<template>
    <div class="know">
        <HeaderPic :topId="topId" :topTitle="topText"></HeaderPic>
        <div class="know-main">
            <div class="know-left">
            <ul class="know-table-list">
                <li :class="{ active: ind == index }" @click="change(index)" v-for="(item,index) of tableList" :key="item">
                <img v-if="ind == index" src="../assets/img/know-school.png" alt=""><span>{{item}}</span>
                </li>
            </ul>
        </div>
        <div class="know-right">
          <div class="know-title">
            {{ind==-1?'通知公告':tableList[ind]}}
            <img src="../assets/img/know-right-title.png" alt="">
          </div>
          <guide ref="child" :type="ind" :detailObj="detailObj" :tableList="tableList" :conList="conList"></guide>
          <el-pagination v-if="pageShow" :current-page.sync="pageNum" :page-size="pageSize" @current-change="handleCurrentChange" layout="prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
    </div>
  </template>
<script>
    import {
        notice,
        noticeDetail,
        increamPageView,
        getPageViewByNameAndId
    } from "@/request/api";
    import HeaderPic from '../components/HeaderPic.vue'
    import guide from '../components/Information/Index.vue'
    export default {
        components: {
            HeaderPic,
            guide
        },
        data() {
            return {
                topPicture: '',
                topText: '',
                topId: '',
                tableList: [],
                ind: -1,
                conList: [],
                detailObj: [],
                // 分页
                total: 0,
                pageNum: 1,
                pageSize: 10,
                pageShow: true
            }
        },
        created() {
            this.initTableList();
            if (!this.$route.query.id) {
                this.notice();
            }
        },
        mounted() {
            if (this.$route.query.id) {
                this.ind = this.$route.query.id - 1;
                this.change(this.ind);
            }
        },
        watch: {
            $route: {
                handler(val, oldval) {
                    if (this.$route.query.id) {
                        this.ind = this.$route.query.id - 1;
                        this.change(this.ind);
                    }
                },
                // 深度观察监听
                deep: true
            }
        },
        methods: {
            initTableList() {
                this.tableList = []
                for (let item of this.headerList[4].content) {
                    this.tableList.push(item.text)
                }
                this.topText = this.headerList[4].title;
                this.topId = this.headerList[4].id;
                // this.menuPic(this.headerList[4].id);
            },
            notice() {
                const _this = this;
                let data = {
                    type: parseInt(_this.ind) + 1, //新闻类型（1：校园动态；2：教育新闻）
                    pageNum: _this.pageNum,
                    pageSize: _this.pageSize
                }
                notice(data).then(res => {
                    _this.conList = res.rows;
                    _this.total = res.total;
                    if (_this.total == 0) _this.pageShow = false;
                    else _this.pageShow = true;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.notice();
            },
            getDetail(id, item) {
                const _this = this;
                // _this.increamPageView(id);
                if (item.type != 1) {
                    this.toNewDetail(id, item.type);
                    return;
                }
                let data = {
                    id: id,
                    type: item.type
                }
                noticeDetail(data).then(res => {
                    _this.detailObj = res.data;
                    _this.getPageViewByNameAndId(id);
                    _this.pageShow = false;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            toNewDetail(id, type) {
                let routeData = this.$router.resolve({
                    name: "tzgg",
                    path: `/tzgg`,
                    query: {
                        id: id,
                        type: type
                    }
                });
                window.open(routeData.href, '_blank');
            },
            //浏览量的增加
            increamPageView(id) {
                // const _this = this;
                let data = {
                    pageName: this.topText,
                    pageId: id
                };
                increamPageView(data).then(res => {
                    // console.log(res);
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            //获取当前页面浏览量
            getPageViewByNameAndId(id) {
                const _this = this;
                let data = {
                    pageName: this.topId,
                    pageId: id
                };
                getPageViewByNameAndId(data).then(res => {
                    _this.detailObj.lookNum = res.data;
                    console.log(_this.detailObj);
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            change(index, type) {
                if (!type) {
                    this.ind = index;
                    this.notice();
                }
                this.pageShow = true;
            }
        }
    }
</script>
<style scoped lang="scss">
    @import "../../public/css/frame.scss";
</style>